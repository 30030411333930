import React, {useEffect} from "react";
import './QandAPage.scss';
import NewsTile from "../mainpage/NewsTile";
import SidebarItem from "../sidebar/SidebarItem";
import SmallNewsTile from "../mainpage/SmallNewsTile";
import type {News} from "../model/News";
import {Helmet} from "react-helmet";
import MyWordCloud from "../sidebar/MyWordCloud";
import GoogleSearch from "../sidebar/GoogleSearch";
import BigSidebarItem from "../sidebar/BigSidebarItem";
import QandAForm from "../sidebar/QAndAForm";

type QandAPageProps = {
    path: string,
    pageContext: {
        data: News[],
        shorts: { headline: string }[],
        wordCloud: [string, number][],
        recent: News[],
    };
}

function QandAPage(props: QandAPageProps) {
    const news = props.pageContext.data.slice(0, 12);
    const recent = props.pageContext.recent;
    const shorts = props.pageContext.shorts;

    useEffect(() => {
        if (window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            const node = document.getElementById("light");
            if (node) node.remove();
        } else {
            const node = document.getElementById("dark");
            if (node) node.remove();
        }
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return <div className={"qanda"}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>BtcLeaks | Questions and Answers | Crypto Bitcoin DeFi</title>
            <link rel="canonical" href={`https://btcleaks.com/qanda`}/>
            <meta name="description"
                  content={`Ask us your question related to Crypto, Bitcoin, DeFi, NFT and more!`}/>
            <meta name="keywords" content={`$Crypto, Bitcoin, News, Q & A, Questions, Feedback, Answers, DeFi, FAQ`}/>
            <meta property="og:title" content={`BtcLeaks | Questions and Answers | Crypto Bitcoin DeFi`}/>
            <meta property="og:description"
                  content={`BtcLeaks | Ask us your question related to Crypto, Bitcoin, DeFi, NFT and more!`}/>
            <meta property="og:image" content="http://btcleaks.com/img_00289.png"/>
            <meta property="og:url" content={`https://btcleaks.com/qanda`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:locale" content="en_GB"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="530"/>
            <meta property="og:image:alt" content={"BtcLeaks.com"}/>
            <meta property="og:image:secure_url" content="https://btcleaks.com/img_00289.png"/>
            <meta property="og:image:type" content="image/png"/>
            <meta property="og:site.name" content={"btcLeaks.com"}/>
            <meta property="article:publisher" content="https://btcLeaks.com"/>
            <meta property="article:author" content="BtcLeaks.com"/>
            <meta name="twitter:site" content="@BtcLeaks"/>
            <meta name="twitter:creator" content="Saitama"/>
            <meta name="twitter:card" content="summary"/>
            <script type="application/ld+json">{`
                {
                    "mainEntityOfPage": { 
                        "@type": "WebPage",
                        "@id": "btcleaks.com"
                    },
                    "description": "BtcLeaks | Ask us your question related to Crypto, Bitcoin, DeFi, NFT and more!",
                    "dateModified": "${new Date().toISOString()}",
                    "datePublished": "${new Date().toISOString()}",
                    "headline": "BtcLeaks | Questions and Answers | Crypto Bitcoin DeFi FAQ",
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "publisher": {
                        "@type": "Organization",
                        "name": "btcLeaks.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://btcleaks.com/image_00289.png"
                        }   
                    }
                }`
            }</script>
        </Helmet>
        <div className={"contentWrapper"}>
            <div className="column1">
                <div className="content">
                    <div className="gcse-searchresults"/>
                    {news && news.length > 0
                        ? <section className="mainSection">
                            <h1>Questions and Answers</h1>
                            {news
                                .filter((it, index) => index < 4)
                                .map((it, i) => <NewsTile news={it} key={"over_" + i}/>)}
                            {news
                                .filter((it, index) => index > 4 && index < 6)
                                .map((it, i) => <SmallNewsTile news={it} key={"overw_" + i}/>)}
                            <article style={{marginBottom: "24px", overflow: "hidden"}}>
                                <ins className="adsbygoogle" id={"dark"}
                                     style={{display: "block"}}
                                     data-ad-format="fluid"
                                     data-ad-layout-key="-gx-y+aj-9s-iz"
                                     data-ad-client="ca-pub-6013495598121737"
                                     data-ad-slot="1313212288"/>
                                <ins className="adsbygoogle" id={"light"}
                                     style={{display: "block"}}
                                     data-ad-format="fluid"
                                     data-ad-layout-key="-hf-1a+ae-9a-gl"
                                     data-ad-client="ca-pub-6013495598121737"
                                     data-ad-slot="9324119159"/>
                            </article>
                            {news
                                .filter((it, index) => index > 5 && index < 13)
                                .map((it, i) => <SmallNewsTile news={it} key={"overw_" + i}/>)}
                            {shorts.length > 0 &&
                            <div>
                                {shorts.map(it => <div>
                                        {it.headline}
                                    </div>
                                )}
                            </div>
                            }
                        </section>
                        : <section className={"nothingFound"}>
                            Nothing found here
                        </section>
                    }
                </div>
            </div>
            <aside className={"column2"}>
                <div className="sidebar">
                    <QandAForm/>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        {recent.length > 0 &&
                        <div className={"sidebarHeadline"}>Recent Crypto News</div>}
                        {recent.slice(0, 1)
                            .map((it, i) => <BigSidebarItem key={"rele_big_" + i} news={it}/>)}
                        {recent.slice(1, 12)
                            .map((it, i) => <SidebarItem key={"rele_" + i} news={it}/>)}
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <ins className="adsbygoogle"
                             style={{display: "block"}}
                             data-ad-client="ca-pub-6013495598121737"
                             data-ad-slot="9302285469"
                             data-ad-format="auto"
                             data-full-width-responsive="true"/>
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <div className={"sidebarHeadline"}>
                            <span>Search</span>
                        </div>
                        <GoogleSearch/>
                        <MyWordCloud wordCloud={props.pageContext.wordCloud}/>
                    </div>
                </div>
            </aside>
        </div>
    </div>;
}

export default QandAPage;
